<template>
  <product title="Amazfit ZenBuds"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="999"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    :video="video"
    buy-link="https://equity.tmall.com/tm?agentId=730567&_bind=true&bc_fl_src=tmall_market_llb_1_895191&llbPlatform=_pube&llbOsd=1"
  ></product>
</template>

<script>
import { CDN_URL } from '@/config'
import Product from '../Product'

export default {
  name: 'zenbuds',
  components: {
    Product
  },
  data () {
    return {
      slogan: '耳塞遮噪设计 | 无感佩戴 | 音律助眠 | 睡眠监测',
      colors: [
        '云母白'
      ],
      video: {
        url: 'https://site-cdn.huami.com/files/amazfit/amazfit-cn/videos/zenbuds.mp4',
        poster: CDN_URL + '/images/poster/zenbuds.png'
      },
      swiperData: [
        [
          CDN_URL + '/images/product/zenbuds/swiper/1.png',
          CDN_URL + '/images/product/zenbuds/swiper/2.png',
          CDN_URL + '/images/product/zenbuds/swiper/3.png',
          CDN_URL + '/images/product/zenbuds/swiper/4.png'
        ]
      ],
      relativedProducts: [],
      detailImages: [
        CDN_URL + '/images/product/zenbuds/detail/s1.jpg',
        CDN_URL + '/images/product/zenbuds/detail/s2.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/zenbuds/03.jpg',
        CDN_URL + '/images/product/zenbuds/detail/s4.jpg',
        CDN_URL + '/images/product/zenbuds/detail/s5.jpg',
        CDN_URL + '/images/product/zenbuds/detail/s6.jpg',
        CDN_URL + '/images/product/zenbuds/detail/s7.jpg',
        CDN_URL + '/images/common/zepp-zenbuds.jpg',
        CDN_URL + '/images/product/zenbuds/detail/s8.jpg'
      ],
      paramImages: [
        'https://site-cdn.huami.com/files/amazfit/mobile/zenbuds/09.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
